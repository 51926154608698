import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const gameModes = () => {
  return (
    <Layout>
      <SEO
        title="Game Modes and Weekly Challenges"
        description="Here are all the Game Modes and Weekly Challenges that are available on PES 2021 myClub."
      />
      <div className="container">
        <h1>Game Modes and Weekly Challenges</h1>
        <ul className="breadcrumb" style={{ paddingTop: "1rem" }}>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>Game Modes and Weekly Challenges</li>
        </ul>
        <p>
          PES have some different Modes and Challenges to keep you busy
          throughout the week. The tasks won't change drastically, since Konami
          rarely produces different challenges, but at least the rewards will be
          reset.
        </p>
        <p>
          Depending on the competition, they will refresh either on Monday or
          Thursday.
        </p>
        <h2>What’s On</h2>
        <p>
          This mode is updated every week (Monday, 8 AM UTC) with new challenges
          where you can usually unlock an Agent if you win (that gives you a
          Silver Ball or better, level 1), extra GP or some other training
          items, such as Position or Skill Trainers.
        </p>
        <p>You have both Online and VS COM challenges:</p>
        <h3 className="bullet">Matchday Mode</h3>
        <p>
          A new competition launched in eFootball PES 2020. This is a daily,
          time-sensitive event with (usually) the following schedule:
        </p>
        <ul>
          <li>15:00-20:00 UTC in Europe;</li>
          <li>17:00-22:00 UTC in America;</li>
          <li>10:00-15:00 UTC in Asia;</li>
          <li>19:00-24:00 in Japan.</li>
        </ul>
        <p>
          Depending on the day of the week, there are currently 2 ways to play
          Matchday: with "Preset Squads" or with "Custom Squads" (your myClub
          squad). Konami seems to be splitting these in 2 parts: you can play
          with the "Preset Squads" from Monday to Wednesday and "Custom Squads"
          from Thursday to Sunday.
        </p>
        <p>
          You can access this mode outside myClub (go to the main screen and
          pick EFOOTBALL / MATCHDAY) on "Preset Squads" days or inside myClub,
          in the MATCH / WHAT'S ON / MATCHDAY section, on "Custom Squads" days.
        </p>
        <p>
          You choose one of two sides and face other players who have chosen the
          other side in Group matches. The side that wins the Group Match stage
          earns an advantage in the Grand Final match (a 1 goal lead) and the
          side that emerges victorious there are crowned Matchday champions.
        </p>
        <p>
          With "Preset Squads", every player has the arrow up (which improves
          their stats). With "Custom Squads", things are a little different.
          Let's say the game is Manchester United Vs Arsenal. You choose
          Manchester United. If you have players in your squad like David De Gea
          or Lindelöf, who belong to the side you choose, they will play with
          the arrow up. Conversely, a player like Guendouzi, who belongs to the
          other side, will have the arrow down, meaning that he will
          underperform.
        </p>
        <p>
          Depending on the number of games you play and how you perform, there
          are usually 3 tiers for Rewards (100 / 500 / 1000 points), awarding
          you eFootball Points, Agents and Trainers. If your side wins, you gain
          15.000 GP; if your side loses, 10.000 GP.
        </p>
        <p>
          You can also earn extra GP if you spectate the Grand Final match
          between the 2 sides, which opposes the two players that have gathered
          more points during that day. After spectating the match, you will gain
          10.000 GP regardless of the outcome.
        </p>
        <p>
          You will also win some special rewards if you reach the Grand Final,
          such as a number of Special EXP Trainers. You will get more items if
          you win the match.
        </p>
        <p>
          All rewards are the same, regardless if you're playing with "Preset
          Squads" or" Custom Squads".
        </p>
        <h3 className="bullet">Online Challenge Cup</h3>
        <p>
          You can play a weekly challenge that on most weeks awards you 10.000
          GP if you win. If you're new to the game, you should play the
          "Introductory Online Challenge", where you get 25.000 GP for each of
          the first 5 online games you play (regardless of the match outcome).
        </p>
        <p>
          There is usually a multiplier that will award you more GP if you use
          specific players in your squad - usually Featured Players from that
          week.
        </p>
        <h3 className="bullet">VS COM Challenge Cup</h3>
        <p>
          Usually you face 3 teams from that week’s featured league. Difficulty
          is usually pretty easy, and by winning all 3 games you are rewarded
          with 5.000 GP (rewards may be different, depending on the week). This
          is also great to repeat for that “First Victory of the Day” bonus
          (3.000 GP).
        </p>
        <h3>myClub Open</h3>
        <p>
          Every weekend, you will also find a special "myClub Open" screen here.
          This is a special competition with shorter matches: 5 minutes, with no
          Extra Time or Penalty Shootout.
        </p>
        <p>
          Your rank is decided by your best results over 3 consecutive games.
          Highest points matter the most, but goal difference and time taken to
          achieve those results are also taken into account.
        </p>
        <p>
          During the Qualifying Round there are no limits to the number of
          matches you can play. The top 16 will progress to the Knockout Phase.
          You need to be logged in the Qualifying Round Menu at the time the
          Qualifying Round ends, as you will lose your spot otherwise.
        </p>
        <p>
          In the Knockout Phase, matches are still 5 minutes, but Penalty
          Shootout is included. Should you reach the final, the match duration
          is 10 minutes, with Extra Time and Penalty Shootout.
        </p>
        <p>
          You will be rewarded with up to 20.000 GP, assuming you reach the
          final and win the game.
        </p>
        <p>
          You won't be able to change your team throughout the competition -
          once you select your 18 players, all you can do is refill their
          stamina or renew their contracts.
        </p>
        <h2>Ranked Match</h2>
        <p>
          You face someone online. A victory will improve your ranking, while a
          defeat will negatively affect your position.
        </p>
        <p>
          By default, Matchmaking usually tries to pair you against a similar
          team - if you have a 5-star team, you will face another 5-star team,
          but if you only have a 3-star team, the game will try to find an
          equivalent.
        </p>
        <p>
          3-star and 5-star teams are the most popular in PES myClub. I would
          recommend you play in 3-star matches if you feel like you don’t have a
          really strong 5-star team yet.
        </p>
        <h3>Weekly PES League</h3>
        <p>
          Every week, from Thursday to Thursday, Konami holds the Weekly PES
          League - where you try to compete for the biggest amount of points. If
          you finish in one of the top spots, you will move to a more
          competitive division the following week.
        </p>
        <p>
          When Matchmaking occurs, you will see your opponent’s Acclaim and
          weekly PES points. If you win, you will get 50% of his points. If you
          lose, you will give 40% of your points to your opponent as well.
        </p>
        <p>
          If you find an opponent with lots of points, you should focus and give
          your all - a win will significantly boost your table position!
        </p>
        <p>
          Be aware that you will compete against random opponents from any
          division - not the ones that stand in the same table as you.
        </p>
        <p>
          Your standing will be determined by the maximum amount of points you
          gathered at any given time during the week - so if you had 500 points
          and suddenly started a losing streak, don’t worry too much.{" "}
        </p>
        <p>
          You can stay #1 even if you finish the week with 0 points, provided
          that at some given time you had more points than your opposition.
        </p>
        <h2>myClub Co-Op</h2>
        <p>
          Up to 6 players can play at the same time (3 versus 3). This is a fun
          way to play with friends, as 6 humans playing together add a lot of
          refreshing moments in the game.
        </p>
        <p>
          You can play a Casual Match or a Clan Match. You can only participate
          in the latter if you belong to a clan with other friends of yours.
        </p>
        <p>
          Only some players from your squad will be picked - you will pay for
          their contracts, even if they don't end up playing. In some games you
          will be the manager of the team, while in others you will just be a
          spectator until the game begins.
        </p>
        <p>
          As you play more and more games, you will not only level up but also
          be rewarded with "Playing Styles" (such as "Finisher" or "One-touch
          Passer").
        </p>
        <h2>Ranked Match (SIM)</h2>
        <p>
          In this mode you won’t play at all. You simply pick a team and compete
          against another player’s team in a Simulated environment.
        </p>
        <p>
          You can act like your team’s manager, making substitutions and
          changing formations, but you won’t be able to control any players.
        </p>
        <p>
          Your opponents aren’t actually sitting there playing against you at
          the same time - you are facing other players’ “Cloud Match” teams, so
          you can take as much time as you want.
        </p>
        <p>
          If you’re more hands-on, SIM matches may seem like a very boring mode
          to you. However, it is actually a very popular way to{" "}
          <Link to="/how-to-get-more-gp/">farm GP in the game</Link>.
        </p>
        <p>
          Although this is a Ranked Match, keep in mind that this SIM Ranking is
          separate from the Ranked Match one.
        </p>
        <p>
          Tip: When the game starts, toggle “Match Data” to skip the game’s cut
          scenes. Matches will end quicker.
        </p>
        <h2>VS COM</h2>
        <p>
          A random match against the AI. If you’re on a winning streak, the
          game’s difficulty will gradually become harder - not only will you
          face stronger teams, but the AI difficulty itself will increase.{" "}
        </p>
        <p>
          You will earn GP as you move to a higher difficulty level, and more GP
          will be awarded to you when you beat a tougher opponent.
        </p>
        <h2>myClub Friendly</h2>
        <p>
          You create/join a match room and play against a friend. These games
          won’t impact your players’ contracts or stamina - but you won’t gain
          GP or EXP either.
        </p>
        <p>
          This is a fun mode when you simply want to prove how good your teams
          are against some of your friends.
        </p>
      </div>
    </Layout>
  )
}

export default gameModes
